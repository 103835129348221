// import  {createRouter,createWebHashHistory} from "vue-router"
import  {createRouter,createWebHashHistory} from "vue-router"



import ManagerView from "@/components/ManagerView";
import HomeView from "../page/HomeView";
import CurrentUserInfo from "@/page/CurrentUserInfo";
import LoginView from "@/page/LoginView";
import RegisterView from "@/page/RegisterView";
import OrderManagement from "@/page/OrderManagement";
import GoodsCategory from "@/page/GoodsCategory";
import StatisticView from "@/page/StatisticView";
import FeedbackView from "@/page/FeedbackView";
import ChatToDoctorView from "@/page/ChatToDoctorView";
//配置信息中需要页面的相关配置

const routes = [
    {
        //访问路径设置
        path:"/ikun",
        component:ManagerView,
        children:[
            {
                path:"home",
                component:HomeView
            },
            {
                path:"currentuserinfo",
                component:CurrentUserInfo
            },
            {
                path:"ordermanagement",
                component:OrderManagement
            },
            {
                path:"registration",
                component: GoodsCategory
            },
            {
                path:"statistic",
                component: StatisticView
            },
            {
                path:"feedback",
                component: FeedbackView
            },
            {
                path: "chat",
                component: ChatToDoctorView
            }

        ]
    },

    {
        path:"/",
        component:LoginView
    },
    {
        path:"/register",
        component:RegisterView
    },

]

const router = createRouter({
     history:createWebHashHistory(),
    routes
})
//导出路由
export default router;