<template>
  <el-menu :default-openeds="['1', '3']" style="height: 100%;overflow-x:hidden;border-right-color: transparent"
           background-color="#3d84a8"
           text-color="#fff"
           active-text-color="#46cdcf"
           :collapse-transition="false"
           :collapse="isCollapse"
           router="true"
  >
    <div  style="height: 60px;line-height: 60px;text-align: center">
      <img src="../assets/logo.png" alt="" style="width: 35px;position: relative;top:5px;margin-right: 5px">

      <b v-show="!isCollapse" style="color: white">医院挂号系统</b>
    </div>
    <el-menu-item index="/ikun/home">
      <el-icon>
        <User></User>
      </el-icon>
        <span>首页</span>
    </el-menu-item>


    <el-menu-item index="/ikun/registration">
      <el-icon>
        <User></User>
      </el-icon>
      <span>挂号功能</span>
    </el-menu-item>
<!--    <el-sub-menu index="2" >-->
<!--      <template #title>-->
<!--        <el-icon>-->
<!--          <Menu></Menu>-->
<!--        </el-icon>-->
<!--        <span>挂号功能</span>-->
<!--      </template>-->
<!--      <el-menu-item-group >-->
<!--        <el-menu-item index="/ikun/goodscategory">商品分类</el-menu-item>-->
<!--        <el-menu-item index="/ikun/allgoods">所有商品</el-menu-item>-->
<!--&lt;!&ndash;        <el-menu-item index="/main/policytag">政策分类标签</el-menu-item>&ndash;&gt;-->
<!--      </el-menu-item-group>-->

<!--    </el-sub-menu>-->



    <el-menu-item index="/ikun/ordermanagement">
      <el-icon>
        <List></List>
      </el-icon>
      <span>出诊信息</span>
    </el-menu-item>

    <el-menu-item index="/ikun/currentuserinfo">
      <el-icon>
        <search></search>
      </el-icon>
      <span>个人信息</span>
    </el-menu-item>

    <el-menu-item index="/ikun/statistic">
      <el-icon>
        <search></search>
      </el-icon>
      <span>统计报表</span>
    </el-menu-item>

    <el-menu-item index="/ikun/feedback">
      <el-icon>
        <List></List>
      </el-icon>
      <span>意见反馈</span>
    </el-menu-item>




  </el-menu>



</template>

<script>
import { List,  Search, User,} from "@element-plus/icons";

export default {
  name: "AsideView",
  data(){

    return {
      router:true
    }
  },
  props:{
    isCollapse:Boolean,
    // logoTextShow:Boolean
  },
  components:{
    User,
    List,
    Search,
    // Menu,
  },
  methods:{
    //打印router测试用
    sss(){
      // this.$router.push({name:"home"})
      console.log(this.$route)
    }
  }
}
</script>

<style scoped>

</style>