<template>
<div>出诊信息
  <!--  搜索框开始-->
  <div style="margin: 10px 0;text-align: left" >
    <!--            用户id搜索-->
    <el-input style="width: 200px" type="text"  placeholder="请输入用户id" v-model="uid">
      <template #prefix>
        <el-icon class="el-input__icon">
          <search></search>
        </el-icon>
      </template>
    </el-input>
    <!--            诊单id输入-->
    <el-input style="width: 200px" type="text"  placeholder="请输入诊单id" class="ml-5"  v-model="orderId">
      <template #prefix>
        <el-icon class="el-input__icon">
          <message></message>
        </el-icon>
      </template>
    </el-input>
    <!--            搜索按钮-->
    <el-button  class="ml-5" type="" style="color: orangered" @click="load">搜索</el-button>
    <el-button  class="mr-5" type="warning" style="color: white" @click="reset">重置</el-button>
  </div>
  <!--  搜索框结束-->

  <!--菜单按钮开始-->
  <div style="margin: 10px 0;text-align: left">
    <el-button type="primary" @click="handleAdd">新增
      <el-icon><CirclePlus></CirclePlus></el-icon>
    </el-button>

    <!--              多选按钮-->
    <el-button type="info" @click="Apearselection">{{duoxuan}}
      <el-icon><CirclePlus></CirclePlus></el-icon>
    </el-button>
    <!--                        确认组件-->
    <el-popover :visible="visible" placement="top" :width="160" class="ml-5">

      <p>您确定批量删除数据吗？</p>
      <div style="text-align: right; margin: 0">
        <el-button size="small" text @click="visible = false">我再想想</el-button>
        <el-button size="small" type="primary" @click="delBatch"
        >确认</el-button
        >
      </div>

      <template #reference>
        <el-button type="danger" >批量删除
          <el-icon><remove></remove></el-icon>
        </el-button>
      </template>
    </el-popover>

<!--    <el-button type="primary">导入-->
<!--      <el-icon><bottom></bottom></el-icon>-->
<!--    </el-button>-->
<!--    <el-button type="primary">导出-->
<!--      <el-icon><top></top></el-icon>-->
<!--    </el-button>-->
  </div>
  <!--  菜单按钮结束-->

  <!--表开始-->
  <el-table :data="tableData" border stripe height="500px" max-height="350px"
            @selection-change="handleSelectionChange">
    <!--            多选框-->
    <el-table-column v-if="letapear" type="selection" width="55" />
    <!--      <el-table-column type="index" :index="getIndex" label="序号" width="0px"/>-->
    <el-table-column prop="mid"  label="诊单ID" width="200" />
    <el-table-column prop="uid"  label="用户ID" width="200" />
    <el-table-column prop="username" label="用户名" width="90" />
    <el-table-column prop="department" label="科室" width="200" />
    <el-table-column prop="vtime" label="出诊时间" width="200" />
    <el-table-column prop="" label="出诊费用" width="200" >
      <template #default="{row}">
        <span>{{row.medicalExpenses}}元</span>
<!--        <el-image style="width: 40%" :src="row.typePoster" fit="contain" :preview-src-list="previewList"></el-image>-->
      </template>

    </el-table-column>
    <el-table-column  label="操作" style="width: 100%;">
      <template #default="scope">
        <el-button style="background-color: #abedd8" @click="handleEdit(scope.row)" >编辑
          <el-icon><edit></edit></el-icon>
        </el-button>
        <!--                        确认组件-->
        <el-popover :visible="visible" placement="top" :width="160" class="ml-5">
          <p>您确定删除吗？</p>
          <div style="text-align: right; margin: 0">
            <el-button size="small" text @click="visible = false">我再想想</el-button>
            <el-button size="small" type="primary" @click="handleDelete(scope.row.orderId)"
            >确认</el-button
            >
          </div>
          <template #reference>
            <el-button style="background-color: #fae3d9">删除
              <el-icon><remove></remove></el-icon>
            </el-button>
          </template>
        </el-popover>
      </template>
    </el-table-column>
  </el-table>
  <!--  表结束-->

  <!--  编辑表单页面开始-->
  <el-dialog title="用户"  v-model="dialogFormVisible" width="30%">
    <el-form :model="form" label-width="80px" size="small">
      <el-form-item label="诊单Id" >
        <el-input v-model="form.mid" autocomplete="off" />
      </el-form-item>
      <el-form-item label="用户Id" >
        <el-input v-model="form.uid" autocomplete="off" />
      </el-form-item>
      <el-form-item label="用户名" >
        <el-input v-model="form.username" autocomplete="off" />
      </el-form-item>
            <el-form-item label="科室" >
              <el-input v-model="form.department" autocomplete="off" />
            </el-form-item>
      <el-form-item label="出诊时间" >
        <el-input v-model="form.vtime" autocomplete="off" />
      </el-form-item>
      <el-form-item label="出诊费用" >
        <el-input v-model="form.medicalExpenses" autocomplete="off" />
      </el-form-item>

    </el-form>
    <template #footer>
                <span class="dialog-footer">
                  <el-button @click="dialogFormVisible = false,this.load">取消</el-button>
                  <el-button type="primary" @click="save">
                    确认
                  </el-button>
                </span>
    </template>
  </el-dialog>
  <!--  编辑表单结束-->

  <!--  页尾 开始-->
  <div style="padding: 10px 0">
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        :page-sizes="[2, 5, 10, 20]"
        :small="small"
        :disabled="disabled"
        :background="background"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
  <!--  页尾结束-->


</div>
</template>

<script>
export default {
  name: "OrderManagement",
  data(){

    return {
      index: 0,
      user: localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")):{},
      // 分页查询数据开始
      tableData :[],
      pageNum:1,
      pageSize:5,
      uid:"",
      orderId:"",
      city:"",

      total:0,
      // 分页查询数据结束
      isCollapse:true,
      sidewidth:200,
      dialogFormVisible:false,
      form:{},
      multipleSelection:[],
      letapear:false,
      duoxuan:"多选"
    }
  },
  created() {
    //请求分页查询数据
    this.load()

  },
  methods:{
    load(){
      this.request.get("/orderslist",{
        params:{
          pageNum:this.pageNum,
          pageSize:this.pageSize,
          uid:this.uid,
          mid:this.orderId,
        }
      }).then(res => {
        console.log(res)
        this.tableData= res.data
        this.total = res.total
        console.log(this.tableData);
        console.log(this.user)
      })
    },
    // 表单保存方法
    save(){
      this.request.post("/saveorder",this.form).then(res => {
        if(res){
          this.$message.success("保存成功")

        }else{
          this.$message.error(("保存失败"))
        }
        this.load();
        this.dialogFormVisible=false;
      })
    },
    getIndex(index) {
      return  index + 1;
    },
    // 展开搜索框
    Apearselection(){
      if(!this.letapear){
        this.letapear=true;
        this.duoxuan="取消多选"
      }else{
        this.letapear=false;
        this.duoxuan="多选"
      }
    },
    // 重置搜索框
    reset(){
      this.uid=""
      this.orderId=""
      this.load()
    }
    ,
    handleSelectionChange(val){
      // console.log(val)
      this.multipleSelection = val;

    },
    handleAdd(){
      this.dialogFormVisible=true;
      this.form={}
    },
    handleEdit(row){
      this.dialogFormVisible=true;
      this.form = Object.assign({},row);

    },
    handleDelete(id){
      this.request.delete("orders/"+id).then(res => {
        if(res){
          this.$message.success("删除成功")

        }else{
          this.$message.error(("删除失败"))
        }
        this.load();
      })
    },
    delBatch(){
      let ids = this.multipleSelection.map(v => v.id)
      console.log(ids)//[{}.{}.{}] => [1,2,3]
      this.request.delete("orders/del/batch",{data:ids}).then(res => {
        if(res){
          this.$message.success("批量删除成功")

        }else{
          this.$message.error(("批量删除失败"))
        }
        this.load();
      })
    },

    handleSizeChange(pageSize){
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum){
      this.pageNum=pageNum
      this.load()
    },
  },
}
</script>

<style scoped>

</style>