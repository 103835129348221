<template>
   <div class="wrapper">
<!--     <div class="logo">-->
<!--       &lt;!&ndash;      <el-image src="../assets/image/8.jpg"></el-image>&ndash;&gt;-->
<!--       <img style="width: 230px;height: auto" src="../assets/image/8.jpg">-->
<!--     </div>-->

     <div class="title">
       <div class="t1">专业医院挂号，</div>
       <div class="t1">上ikun医院挂号系统</div>

     </div>

     <div class="logincss" style="margin: 20px auto;  width: 350px; height: 350px;padding: 20px;
     border-radius: 10px">
       <div   style="margin: 20px 0;text-align: center;font-size: 24px "><b>注册</b></div>
       <el-form  :model=user :rules="rules" label-width="auto">
         <el-form-item  label="用户名" prop="username" >
           <el-input placeholder="请输入账号" size="default"  v-model="user.username" >
             <template #prefix>
               <el-icon class="el-input__icon">
                 <user></user>
               </el-icon>
             </template>
           </el-input>
         </el-form-item>


         <el-form-item  label="密码" prop="password" >
         <el-input placeholder="请输入密码" size="default"  show-password v-model="user.password" >
           <template #prefix>
             <el-icon class="el-input__icon">
               <lock></lock>
             </el-icon>
           </template>
         </el-input>
         </el-form-item>
<!--         确认密码-->
         <el-form-item  label="确认密码" prop="confirmPassword" >
           <el-input placeholder="请确认密码" size="default"  show-password v-model="user.confirmPassword" >
             <template #prefix><el-icon class="el-input__icon"><lock></lock></el-icon></template>
           </el-input>
         </el-form-item>
         <div style="margin: 10px 0; text-align: right">
           <el-button type="primary" size="small" autocomplete="off" @click="register">注册</el-button>
           <el-button type="warning" size="small" autocomplete="off" @click="$router.push('/')">返回登录</el-button>
         </div>
       </el-form>
     </div>
   </div>
</template>

<script>
import {Lock, User} from "@element-plus/icons";
export default {
  name: "LoginView",
  components: {Lock, User},
  data(){

    return {
      user: {},
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {min: 3, max: 5, message: '名称长度应该为3到5', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 3, max: 5, message: '密码长度应该为3到5', trigger: 'blur'},
        ],
        confirmPassword: [
          {required: true, message: '请再次输入密码', trigger: 'blur'},
          {min: 3, max: 5, message: '密码长度应该为3到5', trigger: 'blur'},
        ],
      }
    }
  },
  methods:{
    register(){
      if (this.user.password !== this.user.confirmPassword){
        this.$message.error("两次输入的密码不一致")
        return false
      }
          this.request.post("/register",this.user).then(res =>{
            // if(!res){
            //   this.$message.error("用户名或密码错误")
            // }else {
            //   this.$message.success("登陆成功")
            //   this.$router.push("/new/home")
            // }
            if(res.code === '200'){
              this.$message.success("注册成功")
              setTimeout(()=>{
                this.$router.push('/')
              },2000)
            }else {
              this.$message.error(res.msg)
            }
          })
    }
  }
}
</script>

<style scoped>
.wrapper{

  height: 100vh;
  /*红蓝渐变*/
  /*background-image: linear-gradient(to bottom right,#FC466B,#3F5EFB);*/
  background-image: url("../assets/image/4.jpg");
  background-size: cover;
  background-position: center center;
  /*background-image: linear-gradient(to bottom right, #9b1919, #3ffbf5);*/
  overflow:hidden;
}

.logo{
  float: left;
  width: 20px;
}

.logo>img{
  float: left;
  width: 180px;
}
.title{
  text-align: center;
  float: top;
  justify-content: center;
  align-content: center;
  /*margin-left: 10%;*/
  margin-top: 5%;
  font-size: 40px;
  font-weight: bold;
}

.logincss{
  background-color:rgba(255,255,255,0.1);
  backdrop-filter: blur(10px);
  border:1px solid #ffffff;

}
.e-form-item label {

}


</style>