<template>
  <div class="chat-container">
    <div class="header-container">
      <button class="back-button" @click="goBack">
        返回
      </button>
      <h2>与医生聊天</h2>
    </div>

    <div class="chat-window" ref="chatWindow">
      <div v-for="(message, index) in messages" :key="index" class="message">
        <div v-if="message.from === 'user'" class="user-message">
          <p>{{ message.content }}</p>
        </div>
        <div v-else class="doctor-message">
          <p>{{ message.content }}</p>
        </div>
      </div>
    </div>

    <div class="input-container">
      <input type="text" v-model="newMessage" @keyup.enter="sendMessage" placeholder="在此输入消息" />
      <button @click="sendMessage">发送</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      messages: [],
      newMessage: ''
    }
  },
  mounted() {
    // 从浏览器缓存中获取之前保存的对话消息
    const cachedMessages = localStorage.getItem('chatMessages')
    if (cachedMessages) {
      this.messages = JSON.parse(cachedMessages)
    }
  },
  methods: {
    goBack() {
      this.$router.push('/ikun/home')
      // 返回上一级路由界面的逻辑
    },
    sendMessage() {
      if (this.newMessage.trim() !== '') {
        const userMessage = {
          from: 'user',
          content: this.newMessage.trim()
        }
        this.messages.push(userMessage)

        // 随机生成医生回复的消息
        const doctorReply = this.generateDoctorReply()
        const doctorMessage = {
          from: 'doctor',
          content: doctorReply
        }
        this.messages.push(doctorMessage)

        // 保存对话消息到浏览器缓存中
        localStorage.setItem('chatMessages', JSON.stringify(this.messages))

        this.newMessage = ''
        this.scrollToBottom()
      }
    },
    generateDoctorReply() {
      // 这里可以自定义医生的回复逻辑，例如随机从一组预设的回复中选择一条
      const presetReplies = [
        '你好，有什么问题我可以帮助你解答？',
        '请提供更多详细信息。',
        '建议你来医院进行进一步检查。',
        '不用担心，这是正常现象。',
        '请遵医嘱继续服用药物。'
      ]
      const randomIndex = Math.floor(Math.random() * presetReplies.length)
      return presetReplies[randomIndex]
    },
    scrollToBottom() {
      // 滚动聊天窗口到底部
      this.$refs.chatWindow.scrollTop = this.$refs.chatWindow.scrollHeight
    }
  }
}
</script>

<style scoped>
.chat-container {
  height: 90%;

  max-width: 40%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.header-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.back-button {
  padding: 5px 10px;
  background-color: #71c9ce;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

h2 {
  margin-left: 10px;
  color: #333;
}

.chat-window {
  height: 90%;
  max-height: 80%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow-y: auto;
  background-color: #fff;
}

.message {
  margin-bottom: 10px;
}

.user-message {
  background-color: #a6e3e9;
  padding: 10px;
  border-radius: 5px;
}

.doctor-message {
  background-color: #cbf1f5;
  padding: 10px;
  border-radius: 5px;
  text-align: right;
}

.user-message p,
.doctor-message p {
  margin: 0;
}

.input-container {
  display: flex;
  margin-top: 20px;
}

input[type="text"] {
  flex: 1;
  padding: 7px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

button {
  padding: 7px 15px;
  margin-left: 10px;
  background-color: #71c9ce;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>
