<template>
  <div style="height: 100%">


    <el-container class="layout-container-demo" style="height: 100% " >
      <el-aside :width="sidewidth+'px'" style="height: 100%;box-shadow: 2px 0 6px rgb(0 21 41 / 35%) ;background-color:#3d84a8 " >
        <el-scrollbar >
          <AsideOfManager :is-collapse="isCollapse" :collapse="isCollapse"></AsideOfManager>
        </el-scrollbar>
      </el-aside>

      <el-container style="height: 100%">
        <el-header style=" border-bottom: 1px solid #ccc;"
        >
          <HeaderOfManager :is-collapse="isCollapse" @asideCollapse="collapse"> </HeaderOfManager>
        </el-header>

        <el-main style="height: 100%">

          <router-view  @dianji="getKeywords" :keywords="keywords"
                     ></router-view>

        </el-main>
      </el-container>
    </el-container>
  </div>

</template>

<script >

import AsideOfManager from "@/components/AsideOfManager";
import HeaderOfManager from "@/components/HeaderOfManager";
export default {
  components: {

    AsideOfManager,
    HeaderOfManager
    },

  name: "HomeView",

  data(){
    return {
      isCollapse:false,
      sidewidth:200,
      keywords:'',
      flag:false
    }
  },

  methods: {

    collapse(){ //点击收缩按钮触发
      if(this.isCollapse){
        this.sidewidth=200
      }else {
        this.sidewidth=64
      }
      this.isCollapse=!this.isCollapse
    },
    getKeywords(data){
       this.keywords=data;
    },


  }
}

</script>

<style >
.el-table th{
  /*background-color: #f3e8dd !important;*/
  background-color: #E8E9EB !important;
}
.el-table{
  border-radius: 8px;

}
</style>