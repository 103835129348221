<template>
  <div style="height: 100%">挂号页面

    <!--  搜索框开始-->
    <div style="margin: 10px 0;text-align: left" >
      <!--            分类名称搜索-->
      <el-input style="width: 200px" type="text"  placeholder="请输入医生姓名" v-model="typeName">
        <template #prefix>
          <el-icon class="el-input__icon">
            <search></search>
          </el-icon>
        </template>
      </el-input>
      <!--            分类标题输入-->
      <el-input style="width: 200px" type="text"  placeholder="请输入医生科室" class="ml-5"  v-model="typeTitle">
        <template #prefix>
          <el-icon class="el-input__icon">
            <message></message>
          </el-icon>
        </template>
      </el-input>

      <!--            搜索按钮-->
      <el-button  class="ml-5" type="" style="color: orangered" @click="load">搜索</el-button>
      <el-button  class="mr-5" type="warning" style="color: white" @click="reset">重置</el-button>
    </div>
    <!--  搜索框结束-->

    <!--菜单按钮开始-->
    <div style="margin: 10px 0;text-align: left">
      <el-button type="primary" @click="handleAdd">新增
        <el-icon><CirclePlus></CirclePlus></el-icon>
      </el-button>

      <!--              多选按钮-->
      <el-button type="info" @click="Apearselection">{{duoxuan}}
        <el-icon><CirclePlus></CirclePlus></el-icon>
      </el-button>
      <!--                        确认组件-->
      <el-popover :visible="visible" placement="top" :width="160" class="ml-5">

        <p>您确定批量删除数据吗？</p>
        <div style="text-align: right; margin: 0">
          <el-button size="small" text @click="visible = false">我再想想</el-button>
          <el-button size="small" type="primary" @click="delBatch"
          >确认</el-button
          >
        </div>

        <template #reference>
          <el-button type="danger" >批量删除
            <el-icon><remove></remove></el-icon>
          </el-button>
        </template>
      </el-popover>

<!--      <el-button type="primary">导入-->
<!--        <el-icon><bottom></bottom></el-icon>-->
<!--      </el-button>-->
<!--      <el-button type="primary">导出-->
<!--        <el-icon><top></top></el-icon>-->
<!--      </el-button>-->
    </div>
    <!--  菜单按钮结束-->

    <!--  table开始-->
    <el-table :data="tableData" border stripe height="500px" max-height="510px"
    >
      <!--            多选框-->
      <el-table-column v-if="letapear" type="selection" width="55" />
      <el-table-column type="index" :index="getIndex" label="序号" width="100px"/>
      <el-table-column  label="医生照片" style="width: 30%">
        <template #default="{row}">
          <el-image style="width: 40%" :src="row.typePoster" fit="contain" :preview-src-list="previewList"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="typeName" label="医生姓名" style="width: 40%" />
      <el-table-column prop="" label="所属科室" style="width: 40%" >
        <template #default="{row}">
          <el-tag >{{ row.typeTitle }}</el-tag>
<!--          <el-image style="width: 40%" :src="row.typePoster" fit="contain" :preview-src-list="previewList"></el-image>-->
        </template>
      </el-table-column>

      <el-table-column prop="spareTime" label="空闲时间" style="width: 40%" />

      <el-table-column  label="操作" width="200">
          <template #default="scope">
            <el-button style="background-color: #abedd8" @click="handleEdit(scope.row)" >编辑
              <el-icon><edit></edit></el-icon>
            </el-button>
            <!--                        确认组件-->
            <el-popover :visible="visible" placement="top" :width="160" class="ml-5">
              <p>您确定删除吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="small" text @click="visible = false">我再想想</el-button>
                <el-button size="small" type="primary" @click="handleDelete(scope.row.tid)"
                >确认</el-button
                >
              </div>
              <template #reference>
                <el-button style="background-color: #fae3d9" >删除
                  <el-icon><remove></remove></el-icon>
                </el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>
    </el-table>
    <!--  table结束-->

    <!--  编辑表单页面开始-->
    <el-dialog title="商品种类"  v-model="dialogFormVisible" width="30%">
      <el-form :model="form" label-width="80px" size="small">
        <el-form-item label="分类海报" >
          <el-input v-model="form.typePoster" autocomplete="off" />
        </el-form-item>
        <el-form-item label="分类名称" >
          <el-input v-model="form.typeName" autocomplete="off" />
        </el-form-item>
        <el-form-item label="分类标题" >
          <el-input v-model="form.typeTitle" autocomplete="off" />
        </el-form-item>

      </el-form>
      <template #footer>
                <span class="dialog-footer">
                  <el-button @click="dialogFormVisible = false,this.load">取消</el-button>
                  <el-button type="primary" @click="save">
                    确认
                  </el-button>
                </span>
      </template>
    </el-dialog>
    <!--  编辑表单结束-->

    <!--  页尾 开始-->
    <div style="padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          :page-sizes="[2, 5, 10, 20]"
          :small="small"
          :disabled="disabled"
          :background="background"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <!--  页尾结束-->
  </div>
</template>

<script>
export default {
  name: "GoodsCategory",
  data(){

    return {
      index: 0,
      // 分页查询数据开始
      tableData :[],
      pageNum:1,
      pageSize:5,
      typeName:"",
      typeTitle:"",
      city:"",

      total:0,
      // 分页查询数据结束
      isCollapse:true,
      sidewidth:200,
      dialogFormVisible:false,
      form:{},
      multipleSelection:[],
      letapear:false,
      duoxuan:"多选"
    }
  },
  created() {
    //请求分页查询数据
    this.load()

  },
  methods:{
    load(){
      this.request.get("/goodscategory",{
        params:{
          pageNum:this.pageNum,
          pageSize:this.pageSize,
          typeName:this.typeName,
          typeTitle:this.typeTitle,
        }
      }).then(res => {
        console.log(res)
        this.tableData= res.data
        this.total = res.total
        console.log(this.tableData);

      })
    },
    // 表单保存方法
    save(){
      this.request.post("/savecategory",this.form).then(res => {
        if(res){
          this.$message.success("保存成功")

        }else{
          this.$message.error(("保存失败"))
        }
        this.load();
        this.dialogFormVisible=false;
      })
    },
    getIndex(index) {
      return  index + 1;
    },
    // 展开搜索框
    Apearselection(){
      if(!this.letapear){
        this.letapear=true;
        this.duoxuan="取消多选"
      }else{
        this.letapear=false;
        this.duoxuan="多选"
      }
    },
    // 重置搜索框
    reset(){
      this.typeName=""
      this.typeTitle=""
      this.city=""
      this.load()
    }
    ,
    handleSelectionChange(val){
      // console.log(val)
      this.multipleSelection = val;

    },
    handleAdd(){
      this.dialogFormVisible=true;
      this.form={}
    },
    handleEdit(row){
      this.dialogFormVisible=true;
      this.form = Object.assign({},row);

    },
    handleDelete(id){
      this.request.delete("/category/"+id).then(res => {
        if(res){
          this.$message.success("种类删除成功")

        }else{
          this.$message.error(("种类删除失败"))
        }
        this.load();
      })
    },
    delBatch(){
      let ids = this.multipleSelection.map(v => v.id)
      console.log(ids)//[{}.{}.{}] => [1,2,3]
      this.request.delete("/category/del/batch",{data:ids}).then(res => {
        if(res){
          this.$message.success("种类批量删除成功")

        }else{
          this.$message.error(("种类批量删除失败"))
        }
        this.load();
      })
    },

    handleSizeChange(pageSize){
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum){
      this.pageNum=pageNum
      this.load()
    }
  },

}



</script>

<style scoped>

</style>