<template>
  <div class="total1">
    <div>
      <div id="hospitalized-chart" style="width: 500px; height: 500px;"></div>
    </div>

    <div style="margin-left: 10%">
      <div id="department-chart" style="width: 500px; height: 500px;"></div>
    </div>
  </div>

</template>

<script>
import * as echarts from 'echarts';
export default {

    name: "StatisticView",

  mounted() {
    this.drawHospitalizedChart();
    this.drawDepartmentChart();

  },
  methods: {
      // 条形图
    drawHospitalizedChart() {
      const months = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
      const hospitalized = [150, 120, 170, 200, 180, 190, 220, 250, 230, 210, 190, 160]
      const discharged = [130, 110, 160, 190, 170, 180, 200, 220, 210, 200, 180, 150]

      const chart = echarts.init(document.getElementById('hospitalized-chart'))
      chart.setOption({
        title: {
          text: '住院和出院人数统计'
        },
        legend: {
          data: ['住院', '出院']
        },
        xAxis: {
          type: 'category',
          data: months
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '住院',
            type: 'bar',
            data: hospitalized
          },
          {
            name: '出院',
            type: 'bar',
            data: discharged
          }
        ]
      })
    },
   // 扇形图
      drawDepartmentChart() {
        const departments = ['内科', '外科', '儿科', '眼科', '耳鼻喉科']
        const visitCounts = [200, 150, 100, 120, 80]

        const chart = echarts.init(document.getElementById('department-chart'))
        chart.setOption({
          title: {
            text: '医院病患来访量各科室统计'
          },
          series: [
            {
              name: '科室',
              type: 'pie',
              radius: '50%',
              data: departments.map((department, index) => ({
                name: department,
                value: visitCounts[index]
              })),
              label: {
                show: true,
                formatter: '{b} : {c} ({d}%)'
              }
            }
          ]
        })
      }




  }
}
</script>

<style scoped>
.total1{
  display: flex;
  flex-direction: row;
}
</style>
