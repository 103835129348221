<template>
<div>
<!--  轮播图-->
    <el-carousel :interval="3000" type="card" height="260px" width:auto :autoplay="true">
      <el-carousel-item v-for="image in cimages" :key="image.id">
<!--        <div>{{image.src}}</div>-->
        <img :src=image.src alt="Slide Image" class="carousel-image" />
      </el-carousel-item>
    </el-carousel>

<!--医生推荐-->
  <div class="medical-advice">
    <h2>医疗咨询</h2>
    <p>在这里您可以获取专业的医疗咨询和建议。</p>

    <div class="doctor-recommendation">
      <h3>推荐医生</h3>
     <br>
      <router-link to="/ikun/chat" style="display: flex;flex-direction: row">
<!--        第一组-->
        <div style="display: flex;flex-direction: column;flex: 1">
          <div v-for="doctor in doctors" :key="doctor.id" class="doctor-card">
            <img :src="doctor.image" :alt="doctor.name" class="doctor-image" />
            <div class="doctor-info">
              <h4>{{ doctor.name }}</h4>
              <p>{{ doctor.specialty }}</p>
              <p>{{ doctor.experience }}年经验</p>
            </div>
          </div>
        </div>
<!--第二组-->
        <div style="display: flex;flex-direction: column;flex: 1">
          <div v-for="doctor in doctors" :key="doctor.id" class="doctor-card">
            <img :src="doctor.image" :alt="doctor.name" class="doctor-image" />
            <div class="doctor-info">
              <h4>{{ doctor.name }}</h4>
              <p>{{ doctor.specialty }}</p>
              <p>{{ doctor.experience }}年经验</p>
            </div>
          </div>
        </div>

<!--        第三组-->
        <div style="display: flex;flex-direction: column;flex: 1">
          <div v-for="doctor in doctors" :key="doctor.id" class="doctor-card">
            <img :src="doctor.image" :alt="doctor.name" class="doctor-image" />
            <div class="doctor-info">
              <h4>{{ doctor.name }}</h4>
              <p>{{ doctor.specialty }}</p>
              <p>{{ doctor.experience }}年经验</p>
            </div>
          </div>
        </div>
<!--        第四组-->
        <div style="display: flex;flex-direction: column;flex: 1">
          <div v-for="doctor in doctors" :key="doctor.id" class="doctor-card">
            <img :src="doctor.image" :alt="doctor.name" class="doctor-image" />
            <div class="doctor-info">
              <h4>{{ doctor.name }}</h4>
              <p>{{ doctor.specialty }}</p>
              <p>{{ doctor.experience }}年经验</p>
            </div>
          </div>
        </div>
      </router-link>



    </div>
  </div>

<!--要闻聚焦-->
  <div class="news-focus">
    <h2>要闻聚焦</h2>
    <p>这里是一些重要的新闻和焦点文章。</p>

    <div class="news-list">
      <div v-for="news in newsList" :key="news.id" class="news-item">
        <h3>
          <a :href="news.link" target="_blank">{{ news.title }}</a>
        </h3>
        <p>{{ news.description }}</p>
      </div>
    </div>
  </div>


</div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      cimages:[
        {
          id:1,
          src:require('../assets/CarouselPicture/carouse1.jpg')

        },
        {
          id:2,
          src:require('../assets/CarouselPicture/carouse2.jpg')

        },
        {
          id:3,
          src:require('../assets/CarouselPicture/carouse3.jpg')

        },
      ],
      carouselHeight: '300px', // 设置轮播图的高度\
      doctors: [
        {
          id: 1,
          name: "张医生",
          specialty: "心脏病专家",
          experience: 10,
          image: require("../assets/doctors/doctor1.jpg"),
        },
        {
          id: 2,
          name: "李医生",
          specialty: "儿科医生",
          experience: 8,
          image: require("../assets/doctors/doctor2.jpg"),
        },
        // 可以继续添加更多医生信息

      ],

      newsList: [
        {
          id: 1,
          title: "大医一院连续八年进入中国顶级医院百强！",
          description: "大连医科大学附属第一医院再次入围“中国顶级医院100强”，位列第82位。",
          link: "https://www.dmu-1.com/index-article-detail-id-9690",
        },
        {
          id: 2,
          title: "优化流程，延时增效，全面提升检验服务质量",
          description: "优化检验流程，延时增效，多措并举，以病人为中心，全面提升医疗服务质量。",
          link: "https://www.dmu-1.com/index-article-detail-id-9797",
        },
        {
          id: 3,
          title: "专心治“痔”！分享先进手术技术，术后恢复更快、更方便",
          description: "大医一院肛肠外科与联合路院区日间手术中心联合举办第一期痔疮日间手术治疗",
          link: "https://www.dmu-1.com/index-article-detail-id-9798",
        },
        {
          id: 4,
          title: "凝心聚力共奋进，不忘初心续荣光——大医一院向党的生日献礼",
          description: "大医一院将继续弘扬光荣传统，赓续红色血脉",
          link: "https://www.dmu-1.com/index-article-detail-id-9737",
        },
        // 可以继续添加更多新闻和文章
      ],
    };
  },
}
</script>

<style scoped>
/*轮播图*/

.carousel-image {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  /*object-fit: cover;*/
  /*object-fit: inherit; !* 使用 CSS 缩放图片以适应容器 *!*/
}

:deep(.el-carousel .el-carousel__button){
  background-color: #71c9ce;
  border-radius: 200px;
}

:deep(.el-carousel .el-carousel__button:hover){
  background-color: #a6e3e9;
}

:deep(.el-carousel .el-carousel__button:active){
  background-color: #cbf1f5;
}

:deep(.el-carousel .el-carousel__indicators li ){
  background-color: #e3fdfd;
}

:deep(.el-carousel .el-carousel__indicators li.is-active  ){
  background-color: #cbf1f5;
}



/*医生样式*/

.medical-advice {
  margin-top: 20px;
}

.doctor-recommendation {
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  background-color: #E3FDFD;;
}

.doctor-card {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.doctor-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.doctor-info {
  flex: 1;
}

h2, h3, h4, p {
  color: #333;
}

/*要点咨询样式*/
.news-focus {
  margin-top: 20px;
  background-color: #e3fdfd;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}



.news-list {

  background-color: #cbf1f5;
  padding: 10px;
  border-radius: 5px;
}

.news-item {
  background-color: #a6e3e9;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}
h2 {
  color: #71c9ce;
  margin-bottom: 10px;
}
p {
  color: #333;
  margin-bottom: 10px;
}

h3 a {
  color: #333;
  text-decoration: none;
}
p {
  color: #333;
  margin-bottom: 5px;
}
/*a {*/
/*  color: blue;*/
/*  text-decoration: none;*/
/*}*/

a:hover {
  text-decoration: underline;
}
</style>