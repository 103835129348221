<template>
  <div style="text-align: left; font-size: 12px; line-height: 60px;
          display: flex">

    <div style="flex:1;font-size: 18px">
      <el-icon style="cursor: pointer" @click="collapse">
        <Fold v-if="isCollapse"></Fold>
        <Expand v-else></Expand>
      </el-icon>
    </div>
    <div class="toolbar">

      <el-dropdown style="width: 100px;cursor: pointer"   >
        <div style="display: inline-block">
          <img :src="user.avatarUrl" alt="" style="width: 30px; border-radius: 50%;position: relative; top: 10px;right: 5px">
          <span style="line-height: 60px">{{ user.nickname }}
                <el-icon style="margin-left: 5px"><ArrowDownBold></ArrowDownBold></el-icon>
              </span>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>
              <router-link to="/main/currentuserinfo">个人信息</router-link>
            </el-dropdown-item>

            <el-dropdown-item>
              <!--               <router-link to="/login" style="text-decoration: none" >退出</router-link>-->
              <span  style="text-decoration: none" @click="logout">退出</span>

            </el-dropdown-item>
            <!--            <el-dropdown-item>Delete</el-dropdown-item>-->

          </el-dropdown-menu>
        </template>
      </el-dropdown>

    </div>
  </div>
</template>

<script>
import {ArrowDownBold, Expand, Fold} from "@element-plus/icons";
// import router from "@/router";
export default {

  name: "HeaderView",
  data(){

    return {
      user: localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")):{}
    }
  },
  components:{

    Expand,
    ArrowDownBold,
    Fold,
  },
  props:{
    isCollapse:Boolean,
  },

  methods:{
    collapse(){
      this.$emit("asideCollapse")
    },
    logout(){
      this.$router.push("/")
      // location.replace("/login")
      localStorage.removeItem("user")
      this.$message.success("退出成功")
      this.triggeringElement = null
      // location.reload()
    }
  }
}
</script>

<style scoped>

</style>