<template>
  <div class="total1">

    <div className="container">
      <div className="feedbacks">
        <h2>已收到的建议:</h2>
<!--        一个静态数据-->
        <div className="note">
          <p>姓名：ikun</p>
          <p>邮箱：ctrl@sushan.666</p>
          <p>建议医院食堂每天早晨都有煮鸡蛋吃</p>
        </div>
        <div v-for="item in receivedFeedbacks" :key="item" className="note">
          <p>{{ item.name }}</p>
          <p>{{ item.email }}</p>
          <p>{{ item.message }}</p>
        </div>
      </div>
    </div>
    <div className="container">
      <h1>意见反馈</h1>
      <form>
        <label htmlFor="name">姓名:</label>
        <input type="text" id="name" v-model="newFeedback.name">
        <label htmlFor="email">邮箱:</label>
        <input type="email" id="email" v-model="newFeedback.email">
        <label htmlFor="message">建议:</label>
        <textarea id="message" v-model="newFeedback.message"></textarea>
        <button  @click="submitFeedback" style="background-color: #71c9ce">提交</button>
      </form>

    </div>


  </div>

</template>

<script>

export default {
  name: "FeedbackView",
  data() {
    return {
      newFeedback: {
        name: '',
        email: '',
        message: ''
      },
      receivedFeedbacks: []
    }
  },
  methods: {
    submitFeedback() {
      const feedback = {
        name: this.newFeedback.name,
        email: this.newFeedback.email,
        message: this.newFeedback.message
      }
      // 这里可以将 feedback 保存到数据库或将其发送给服务器端进行处理

      // 添加已收到的建议到展示列表中
      this.receivedFeedbacks.push(feedback)

      // 清空输入框
      this.newFeedback.name = ''
      this.newFeedback.email = ''
      this.newFeedback.message = ''
    }
  }
}
</script>

<style scoped>
.total1{
  display: flex;
  flex-direction: row;
}

.container {
  width: 40%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #e3fdfd;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

form {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #555;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

textarea {
  height: 150px;
  resize: vertical;
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

h2 {
  margin-bottom: 10px;
  color: #555;
}

.note {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #333;
  background-color: #fff;
}

.note p:last-child {
  margin-bottom: 0;
}
</style>
