<template>
   <el-card style="width: 500px;text-decoration: none;">
     <div style="margin-bottom: 20px;border-bottom: 1px solid #C9C9C9">个人信息查看及更改</div>
     <div style="display: flex" >
       <div >
         <img :src="user.avatarUrl" alt="" style="width: 100px; height: 100px; border-radius: 50%;position: relative; justify-content: center" class="mr-5">
       </div>

       <el-form :model="form" label-width="80px" size="small" style="flex: 5">
         <el-form-item label="用户名" >
           <el-input v-model="form.username" autocomplete="off" />
         </el-form-item>
         <el-form-item label="密码" >
           <el-input v-model="form.password" autocomplete="off" />
         </el-form-item>
         <el-form-item label="性别" >
           <el-input v-model="form.gender" autocomplete="off" />
         </el-form-item>
         <el-form-item label="城市" >
           <el-input v-model="form.city" autocomplete="off" />
         </el-form-item>
         <el-form-item label="电话" >
           <el-input v-model="form.phone" autocomplete="off" />
         </el-form-item>
         <el-form-item label="邮箱" >
           <el-input v-model="form.email" autocomplete="off" />
         </el-form-item>

         <span class="dialog-footer" >

                  <el-button  style=" width: 30%;height: 20px; margin-left: 100px" type="warning" @click="save">
                    确认
                  </el-button>
<!--            <el-button @click="dialogFormVisible = false,this.load">取消</el-button>-->
                </span>
       </el-form>
<!--     底部按钮开始-->

     </div>
   </el-card>
</template>

<script>
export default {
  name: "CurrentUserInfo",
  data(){
    return {
      form:{},
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")):{}
    }
  },
  created() {
    console.log(this.user)
    this.request.get(""+this.user.username).then(res => {
      if(res.code === '200'){
        console.log(res)
        this.form = res.data;
      }


    })
  },
  methods:{
    save(){
      this.request.post("/saveuser",this.form).then(res => {
        if(res){
          this.$message.success("修改成功")

        }else{
          this.$message.error("修改失败")

        }

      })
    },
  }
}
</script>

<style scoped>

</style>