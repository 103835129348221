// main.ts
import { createApp } from 'vue'
import 'element-plus/dist/index.css'
import element from 'element-plus'
import 'dayjs/locale/zh-cn' //中文
import locale from 'element-plus/lib/locale/lang/zh-cn' //中文
import App from './App.vue'
import router from "@/router";
import './assets/global.css'
import request from '@/utils/request'
const app = createApp(App)

app.use(element, {size:"small", locale })
app.use(router)
app.mount('#app')
app.config.globalProperties.request=request